@import "../../../css-config/mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.Wrapper {
  // background: linear-gradient(90deg, #880fc4 0%, #1d39f2 100%);
  min-height: 40vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #100f0f;


  .Cards {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 1200px;
    margin: 0 auto;

    .Card {
      width: 301px;
      height: 232px;
      border-radius: 15px;
      margin: 5px;
      padding: 20px 30px;
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 10px 20px 25px rgba(0, 0, 0, 0.2);

      @include md {
        width: 356px;
        height: 250px;
      }

      img {
        height: 40px;
        width: 40px;
        border: 1px solid #511212;
        top: 3px;
        left: 26px;
        border-radius: 50%;
        object-fit: cover;
        position: absolute;
        box-shadow: 20px 10px 25px rgba(0, 0, 0, 0.3);

        @include md {
          height: 70px;
          width: 70px;
          border: 2px solid #511212;
          top: -20px;
          left: 20px;
        }
      }

      .Message {
        font-family: Courgette;
        font-size: 12px;
        margin-top: 34px;
        margin-bottom: 16px;

        @include md {
          font-size: 16px;
          margin-top: 40px;
          margin-bottom: 50px;
        }
      }

      .Title {
        font-family: Courgette;
        font-weight: 500;
        margin-bottom: -7px;
        font-size: 15px;

        @include md {
          font-size: 18px;
          margin-bottom: -2px;
        }
      }

      .Desc {
        color: rgb(35, 29, 29);
        font-weight: 400;
        font-size: 10px;
        margin-left: 4px;

        @include md {
          font-size: 14px;
        }
      }
    }

  }


}