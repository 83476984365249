@import "../../css-config/mixins.scss";

.main-headers {
    width: 100%;
    padding: 0 20px;
    background-image: linear-gradient(90deg,#000000,#751113);
    color: white;
    z-index: 99;

    // @include sm {
    //     // background-image: linear-gradient(90deg,#000000,#ac8484);   
    // }
    @include md {
        padding: 0 90px;
    }
    .header-contents{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 53px;
        max-width: 208px;
        margin: auto;
        @include md {
            height: 150px;
            max-width: 1200px;
            
        }
        .center{
            display: flex;
            align-items: center;
            transform: translate(-13%);
            @include md {
                display: flex;
                font-size: 30px;
                position: absolute;
                align-items: center;
                transform: translate(22%);
            }
            img {
                width: 100px;
                @include md{
                    width: 300px;
                }
            }  
            

        }

    }

   
}
