@import "../../css-config/mixins.scss";

.footer {
    width: 100%;
    background-image: linear-gradient(90deg, #000000, #751113);
    border-radius: 11% 11% 0 0;

    .footer-content {
        padding: 50px 20px;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-flow: wrap;
        gap: 10px;

        @include md {
            justify-content: space-between;
            padding: 50px 10px;
        }

        .col {
            max-width: 300px;

            .title {
                margin-bottom: 20px;
                font-size: 15px;
                // border-bottom: 4px solid red;
                color: rgb(239, 239, 239);

                @include md {
                    font-size: 30px;
                }
            }

            .text1 {
                color: rgb(255 255 255 / 64%);
                ;
                font-size: 10px;

                @include md {
                    font-size: 14px;
                }

                .social-media {
                    display: flex;
                    gap: 10px;

                    .icon {
                        margin-bottom: 10px;
                        width: 28px;
                        height: 28px;
                        background-color: rgba(12, 103, 239, 0.909);
                        border-radius: 50%;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        &.v3 {
                            background: linear-gradient(45deg, rgb(62, 62, 152), transparent), radial-gradient(rgb(254, 0, 0), transparent);
                        }

                        &.v4 {
                            background-color: rgb(44, 173, 54);
                        }

                        @include md {
                            width: 40px;
                            height: 40px;
                            background-color: rgba(12, 103, 239, 0.909);
                            border-radius: 50%;
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.v3 {
                                background: linear-gradient(deg, rgba(171, 162, 65, 0.774), transparent), radial-gradient(rgb(255, 0, 0), transparent);
                            }

                            &.v4 {
                                background-color: rgb(44, 173, 54);
                            }
                        }
                    }
                }

            }

            .c-item {
                display: flex;
                margin-bottom: 7px;

                @include md {}

                &.v1 {
                    color: #cb7818;
                }

                &.v2 {
                    color: #65c582;
                }

                &.v3 {
                    color: #0082f4;
                }

                svg {
                    flex-shrink: 0;
                    font-size: 15px;
                    margin-right: 10px;
                    margin-top: 4px;
                }

            }

            span {
                &.text1 {
                    display: block;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .bottom-bar {
        border-top: 1px solid rgb(143, 140, 140);

        .bottom-bar-content {
            padding: 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            gap: 10px;

            @include md {
                padding: 0;
                height: 60px;
                max-width: 1200px;
                margin: 0 auto;
                flex-direction: row;
                justify-content: space-between;
                text-align: left;
            }

            .text1 {
                font-size: 12px;
                color: rgb(131, 130, 130);
            }
        }
    }
}