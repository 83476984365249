@import "../../css-config/mixins.scss";

.products-containers {
    margin: 50px 0;

    @include md {
        margin: 50px 0;

    }

    .sec-headings {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;

        @include md {
            margin-bottom: 35px;
            font-size: 35px;
            text-align: center;
        }

        &:after {

            content: "";
            display: block;
            margin-top: 5px;
            margin-left: 7.5rem;
            width: 140px;
            height: 3.4px;
            background-image: linear-gradient(40deg, #d63c3e, #e1c08f);
            border-radius: 10px;

            @include md {
                margin-top: 5px;
                margin-left: 29.4rem;
                width: 203px;

            }

        }
    }

}