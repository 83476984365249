  
  h2 {
    cursor: pointer;
    font-size: 19px;
    padding: 3px;
    
  }
  
  .product--images {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    // margin-top: 0px;
    border-radius: 20px;
    
  }
  
  .cards {
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: auto 2rem;
    text-align: center;
    font-family: arial;
    background-image: linear-gradient(90deg,#362b2b,#914041);
    color: white;
    border-radius: 25px;
    box-shadow: 0 3.1rem 1.8rem rgba(121, 89, 89, 0.2);
    border-radius: 35px;
  
  }
  
  .price {
    color: white;
    font-size: 25px;
    margin-bottom: 15px;
  }
  
  .text {
    font-size: 10px;
    margin-bottom: 20px;
    color: #dacece;
  }
  
  .card button {
    border: none;
    outline: 0;
    padding: 8px;
    color: white;
    background-color: #d92121;
    text-align: center;
    cursor: pointer;
    width: 60%;
    font-size: 18px;
    margin-bottom: 30px;
    border-radius: 8px;
  }
  
  .card button:hover {
    opacity: 0.7;
  }