@import "../../css-config/mixins.scss";

.main-header {
    width: 100%;
    padding: 0 20px;
    background-image: linear-gradient(90deg,#000000,#6e1010);
    // background-image: linear-gradient(to right, #8e2de2, #4a00e0);
    color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 99;

    // @include sm {
    //     // background-image: linear-gradient(90deg,#000000,#ac8484);   
    // }
    @include md {
        padding: 0 40px;
    }
    .header-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        max-width: 1200px;
        margin: auto;
        @include md {
            height: 85px;
        }
        .center{
            list-style-type: none;
            display: none;
            gap: 25px;
            @include md {
                display: flex;
                font-size: 22px;
                position: absolute;
                left: 65%;
                transform: translateX(-50%);
            }
   
            li {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
            }

        }
        .left{
            font-size: 22px;
            font-weight: 700;
            cursor: pointer;
            img {
                width: 80px;
                @include md {
                    width: 120px;
                }
            } 
            
        }
        .right{
            display: flex;
            align-items: center;
            gap: 20px;
            @include md {
                font-size: 24px;
            }
            svg {
                font-size: 25px;
            }
            .cart-icon {
                position: relative;
                span {
                    min-width: 20px;
                    text-align: center;
                    background-color: #000000;
                    padding: 2.5px;
                    position: absolute;
                    top: -5px;
                    right: -12px;
                    font-size: 12px;
                    line-height: 1;
                    border-radius: 10px;
                }
            }
        }
    }

    &.sticky-header{
        position: sticky;
        top: -1px;
        transform: translateY(-85px);
        animation: stickyHeader 0.3s ease forwards;
    }
}

@keyframes stickyHeader {
    0% {
        transform: translateY(-85px);
    }
    100% {
        transform: translateY(0);
    }

}