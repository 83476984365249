@import "../../../css-config/mixins.scss";

h2 {
  margin-bottom: 1px;
  font-size: 19px;
}

.product--image {
  width: 53%;
  height: 10rem;
  object-fit: cover;
  margin-top: 31px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;

  @include md {
    width: 75%;
    height: 17rem;
    object-fit: cover;
    margin-top: 31px;
    border-radius: 20px;
    margin-bottom: 10px;

  }
}

.card {
  box-shadow: 0 3.1rem 1.8rem rgba(40, 26, 26, 0.2);
  margin: auto 3rem;
  text-align: center;
  font-family: arial;
  background-image: linear-gradient(90deg, #000000, #751113);
  color: white;
  border-radius: 25px;

  @include md {
    margin: auto 2rem;
  }

}

.price {
  color: white;
  font-size: 25px;
  margin-bottom: 15px;
}

.text {
  font-size: 10px;
  color: #dacece;
  width: 90%;
  margin: 12px;
}

.card button {
  border: none;
  outline: 0;
  padding: 8px;
  color: white;
  background-color: #d92121;
  text-align: center;
  cursor: pointer;
  width: 60%;
  font-size: 18px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.card button:hover {
  opacity: 0.7;
}