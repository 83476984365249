@import "../../css-config/mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.about {
    width: 100%;
    background-image: linear-gradient(90deg, #000000, #751113);
    border-radius: 0 0 0 0;

    .about-content {
        padding: 20px 20px;
        max-width: 1200px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        flex-flow: wrap;
        gap: 10px;

        @include md {
            text-align: center;
            padding: 70px 10px;
        }

        .col {
            max-width: 1200px;

            .title {
                text-align: center;
                margin-bottom: 30px;
                font-size: 18px;
                // border-bottom: 4px solid red;
                color: rgb(239, 239, 239);

                @include md {
                    font-size: 40px;
                }
            }

            .text {
                font-family: Courgette;
                color: rgb(255 255 255 / 64%);
                ;
                font-size: 10px;

                @include md {
                    font-size: 17px;
                    font-weight: 300;
                }

            }

        }
    }

}