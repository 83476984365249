@import "../../css-config/mixins.scss";
.products-containerss {
    margin: 50px 0;
    @include md {
        margin: 100px 0;
        
    }

}

.product--imagess {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    // margin-top: 0px;
    border-radius: 20px;
    
  }
  
  .cardss {
     box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2);
    //  box-shadow: 0rem 0.6rem 10.4rem 10.8rem rgba(40, 26, 26, 0.2);
    margin: auto 1rem;
    text-align: center;
    font-family: arial;
    color: white;
    border-radius: 25px;
  
  }
  

  
